import React from 'react';
import '../../App.css';

import { Col, Container, Row,  ListGroup, Card, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function SignUp() {



  
  return <div className='sign-up' >
	 <Container fluid={true} className="p-0" >
	 <Row className='mb-2'>
            <div className="titleDiv">
                <Container>
                    <h2 className='title'>Contact Us</h2>
                </Container>
            </div>
        </Row>
		
	</Container>

	<Container>
		<Row>
			<Col lg='6'>
			<Card style={{marginTop: '5px'}}>
					<Card.Img variant="top" src="../../../images/picture.png" />
					<Card.Body>
				<Card.Title>Contact us</Card.Title>
				<Card.Text>
				Any questions ? Feel free to contact us 
				</Card.Text>
					</Card.Body>
					<ListGroup className="list-group-flush">
						<ListGroup.Item> <i className='fas fa-envelope' /> <a href='mailto:pa@onlineforu.co.uk'>pa@onlineforu.co.uk</a></ListGroup.Item>
						<ListGroup.Item><i className='fas fa-phone' /> <a target="_black"  href='tel:+447503661320'>07503661320</a></ListGroup.Item>
						<ListGroup.Item><i className='fas fa-whatsapp' /> <a target="_black"  href='https://wa.me/+447503661320'>What App</a></ListGroup.Item>
					</ListGroup>
					<Card.Body>
					Address: London NW11 9EJ
			</Card.Body>
			</Card>
			
			
			</Col>
			<Col>
			
			<Card style={{marginTop: '5px'}}>
					<Card.Img variant="top" src="../../../images/picture.png" />
					<Card.Body>
				<Card.Title>Sign Up</Card.Title>
				<Card.Text>
				Sign up to our mailing list to get out email updates
				</Card.Text>
					</Card.Body>
					<ListGroup className="list-group-flush">
				<ListGroup.Item>
					<a 
						href='http://eepurl.com/ibUNgj' 
						target="_blank" 
						rel="noreferrer" 
						className="btn-mobile btn btn--outline btn--medium"
						style={{width: '100%'}}
					><Button variant="primary" size="lg" style={{width: '100%'}}>Sign up</Button></a></ListGroup.Item>
				
					</ListGroup>
					
			</Card>
			
			</Col>
		</Row>
	

	

	</Container>

 </div>;
}

