import React from 'react';
import '../../App.css';
import ProductDetails from '../productDetails/ProductDetails';

export default function ProductInfo(props) {
  window.scrollTo(0, 0)
  
  return (
  <div className='productInfo'>
   
   
    
    <ProductDetails name={props.name}/>
    
  </div>
  )
}