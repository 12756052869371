export const productImages = 
    {
        'mag-shapes' : {
            'name' : "Mag Shapes",
            'videos' : [
              '1.mp4'
             ],
             'images' : [
                '1.png',
                '3.jpg',
                '4.jpg',
                '5.jpg',
                '6.jpg',
                '7.jpg',
                'B15A0031.jpg',
                'B15A0056_clipped_rev_1.jpg',
                'B15A0073_clipped_rev_1.jpg',
                'B15A0119_clipped_rev_1.jpg',
                'B15A0875.jpg',
                'B15A1101.jpg',
                'B15A1135.jpg',
                'B15A1234.jpg', 
                'B15A1498_clipped_rev_1.jpg',
                'B15A1548. CMYK.jpg',
                'B15A1599_clipped_rev_1.jpg',
                'B15A2210_clipped_rev_1.jpg',
                'B15A2708_clipped_rev_1.jpg',
                'B15A3599_clipped_rev_1.jpg',
                'B15A3660_clipped_rev_1.jpg',
                'B15A3758_clipped_rev_1.jpg',
                'B15A3765_clipped_rev_1.jpg',
                'B15A3767_clipped_rev_1.jpg',
                'B15A3813_clipped_rev_1.jpg',
                'B15A3996_clipped_rev_1.jpg',
                'B15A5333_clipped_rev_1.jpg',
                'B15A5391_clipped_rev_1.jpg',
                'B15A5440_clipped_rev_1.jpg',
                'B15A5872_clipped_rev_1.jpg',
                'B15A9611_clipped_rev_1.jpg',
                'B15A9613_clipped_rev_1.jpg',
                'B15A9783_clipped_rev_1.jpg',
                'B15A9804_clipped_rev_1.jpg',
                'B15A9815_clipped_rev_1.jpg',
                'B15A9826_clipped_rev_1.jpg',
                'B15A9839_clipped_rev_1.jpg',
                'B15A9841_clipped_rev_1.jpg',
                'B15A9851_clipped_rev_1.jpg',
                'B15A9868_clipped_rev_1.jpg',
                        ]
        },
            'mag-tracks' : {
                'name' : "Mag Tracks",
                'videos' : [
                    '1.mp4', '2.mp4', '3.mp4', '4.mp4'
                 ],
                 'images' : [
                    '1.jpg',
                    '2.jpg', 
                    '4.jpg', 
                    '5.jpg'
                 ]
                },
                'mag-blocks' : {
                    'name' : "Mag Shapes for Lego Blocks",
                    'videos' : [
                        
                     ],
                     'images' : [
                        '4-Modes 01.jpg',
                        '4-Modes 02.jpg',
                        '4-Modes 03.jpg',
                        '4-Modes 04.jpg',
                        '4-Modes 05.jpg',
                        '4-Modes 06.jpg',
                        '4-Modes 07.jpg',
                        '4-Modes 08.jpg',
                        '4-Modes 09.jpg',
                        '4-Modes 11.jpg',
                        '4-Modes 12.jpg',
                        '4-Modes 13.jpg',
                        '4-Modes 14.jpg',
                        '4-Modes 15.jpg',
                        '4-Modes 16.jpg',
                        '4-Modes 17.jpg',
                        '9pcs.jpg',
                        'Z01.jpg',
                     ]
                    }
            }


export const itemList = [
{
            src:'../../images/box-lower-res.jpg',
            text:'Mag Shapes Magnetic Tiles 65 peaces',
            label:'mag-shapes-73',
            path:'/item/mag-shapes-73',
            barcode: "5060831600508",
            weight: "2.39kg",
            dimations: '9.5 X 15 x 22 cm',
            price: 50,
            howToUseLink: '/product/mag-shapes',
            stores:[
                [
                    {store: 'eBay'},
                    {link: "https://www.ebay.co.uk/itm/Mag-Shapes-Magnetic-Tiles-65-peaces-/266028196683"}
                ],
                [
                    {store: 'Amazon'},
                    {link: "https://www.amazon.co.uk/dp/B0BP7WFXVJ"}
                ]
            ],
            
            shortDesc: "",
            desciption:'<div id="dasdesc"><ul> <li>65 piece magnetic tile set</li> <li>Suitable from age 3+</li> <li>Made from non-toxic, BPA-free materials</li> <li>Encourages hand eye coordination, motor skills, problem solving, and creativity.</li> </ul> <p>CONSTANT CREATIVITY</p> <p>The 65 piece kit is made up of a variety of 7 different shapes and accessories in various colours for unlimited creative designs. The construction kit features easy-to-handle tiles with magnetic edges for easy and safe building. Add more pieces for limitless scalability and endless hours of fun.&nbsp;</p> <p></p> <p>included :</p> <p>24 x Squares</p> <p>18 x Equilateral Triangle</p> <p>6 x Rectangle</p> <p>2 x Large Squares</p> <p>6 x Isosceles triangle&nbsp;</p> <p>8 x Long Isosceles triangle&nbsp;</p> <p>1 x Car</p> <p></p> <p>BRAIN DEVELOPMENT</p> <p>Promotes problem-solving skills, fine motor skills, hand eye coordination, imagination, and team building. The kit includes sloped pieces which allows for multi storeyed tracks which encourages intuitive playing through linear and lateral thinking.&nbsp;</p> <p>EASY TO USE</p> <p>The quick-connect magnetic system allows the construction to be built and taken apart with immeasurable ease. Children will not get frustrated at difficult deconstruction which will encourage constant creative motion. Easy to pack away and store.&nbsp;</p> <p>PLAY AND LEARN</p> <p>Bright colours, geometric shapes, numbers, physics, gravity, architecture etc. This fun, bright, and creative toy has endless teaching possibilities, while playing and having fun. The street and traffic signs allow the children to mimic a real-life racetrack environment thereby encouraging awareness of their own surroundings.&nbsp;</p> <p>SAFE AND STRONG</p> <p>Made from durable, non-toxic, BPA free, MABS plastic material, which is heat resistant and less likely to break.&nbsp;</p>            </div>',

},{
            src:'../../images/116.jpg',
            text:'Mag Shapes Magnetic Tiles 116 Pieces Fun Creative Educational Race Track Set with 2 LED Cars.',
            label:'mag-shapes-116',
            path:'/item/mag-shapes-116',
            barcode: 5060831600515,
            weight: "4.95 KG",
            dimations: '24 X 24 X 28 cm',
            price: 100,
            howToUseLink: '/product/mag-tracks',
            stores:[
                [
                    {store: 'eBay'},
                    {link: "https://www.ebay.co.uk/itm/255794612704"}
                ],
                [
                    {store: 'Amazon'},
                    {link: "https://www.amazon.co.uk/dp/B0BKR55LC3"}
                ]
            ],
            
            shortDesc:"<ul class='list-group'><li class='list-group-item'>116 piece magnetic tile set</li><li class='list-group-item'>Suitable from age 3+</li><li class='list-group-item'>Made from non-toxic, BPA-free materials</li><li class='list-group-item'>Includes 2 LED light up cars (requires 2xAA batteries per car)</li><li class='list-group-item'>Encourages hand eye coordination, motor skills, problem solving, and creativity.</li></ul>",
            desciption:"<p>CONSTANT CREATIVITY</p><p>The 116 piece kit is made up of a variety of 13 different shapes and accessories in various colours and 2 racecars for unlimited creative designs. The construction kit features easy-to-handle tiles with magnetic edges for easy and safe building. Add more pieces for limitless scalability and endless hours of fun.&nbsp;</p><p>BRAIN DEVELOPMENT</p><p>Promotes problem-solving skills, fine motor skills, hand eye coordination, imagination, and team building. The kit includes sloped pieces which allows for multi storeyed tracks which encourages intuitive playing through linear and lateral thinking.&nbsp;</p><p>EASY TO USE</p><p>The quick-connect magnetic system allows the construction to be built and taken apart with immeasurable ease. Children will not get frustrated at difficult deconstruction which will encourage constant creative motion. Easy to pack away and store.&nbsp;</p><p>PLAY AND LEARN</p><p>Bright colours, geometric shapes, numbers, physics, gravity, architecture etc. This fun, bright, and creative toy has endless teaching possibilities, while playing and having fun. The street and traffic signs allow the children to mimic a real-life racetrack environment thereby encouraging awareness of their own surroundings.&nbsp;</p><p>SAFE AND STRONG</p><p>Made from durable, non-toxic, BPA free, MABS plastic material, which is heat resistant and less likely to break.&nbsp;</p>"

            
},
{
    src:'../../images/large-mag-tiles/4.jpg',
    text:'2 Super Large Mag Tiles',
    label:'t01',
    path:'/item/t01',
    barcode: "5060831600768",
    weight: "2",
    dimations: '30 X 30 x 2 cm',
    price: 19.99,
    howToUseLink: '/product/mag-shapes',
    stores:[
        [
            {store: 'eBay'},
            {link: "https://www.ebay.co.uk/itm/256252609913"}
        ],
        [
            {store: 'Amazon'},
            {link: "https://www.amazon.co.uk/dp/B0CKWM2H4H"}
        ]
    ],
    
    shortDesc: `<ul class="list-group">
    <li class="list-group-item">Educational magnetic building tiles blocks toys</li>
    <li class="list-group-item">Plastic magnetic blocks toys set for kids</li>
    <li class="list-group-item">CPSC, CE, EN71, ASTM certified</li>
    <li class="list-group-item">The magnetic toys belong to STEAM toy.</li>
    <li class="list-group-item">The products can be freely built and created.</li>
    <li class="list-group-item">They can create two-dimensional and three-dimensional space models.</li>
    <li class="list-group-item">In addition to exercising space thinking, it can also help kids understand basic shapes and colors.</li>
    <li class="list-group-item">Further used as team competition and family interaction.</li>
    <li class="list-group-item">100% Non-toxic ABS Plastic + Strong Magnet</li>
  </ul>
  `,
    desciption:'<div id="dasdesc">Educational magnetic building tiles blocks toys plastic magnetic blocks toys set for kids CPSC, CE, EN71, ASTM The magnetic toys belong to STEAM toy. The products can be freely built and created. They can create two-dimensional, and three-dimensional space models. In addition to exercising space thinking, it can also help kids to understand basic shapes and colors, further used as team competition and family interaction. 100% Non-toxic ABS Plastic + Strong Magnet</div>',

},
{
    src:'../../images/eletric-cars.jpg',
    text:'2 Mag Cars, for Racing Track Children Electric Toys Car For Kids',
    label:'g8218',
    path:'/item/g8218',
    barcode: "5060831600775",
    weight: "2",
    dimations: '20 X 20 x 20 cm',
    price: 12.99,
    howToUseLink: '/product/mag-tracks',
    stores:[
        [
            {store: 'eBay'},
            {link: "https://www.ebay.co.uk/itm/256252962721"}
        ],
        [
            {store: 'Amazon'},
            {link: "https://www.amazon.co.uk/dp/B0CKWN4ZXF"}
        ]
    ],
    
    shortDesc: `<ul class="list-group">
    <li class="list-group-item">Perfect Replacement Cars: Ideal replacements for magnetic tile sets, ensuring uninterrupted play.</li>
    <li class="list-group-item">Seamless Compatibility: Integrates with popular magnetic tile sets, a perfect match for your collection.</li>
    <li class="list-group-item">Durable and Safe: Crafted from high-quality, child-friendly materials for lasting play and safety.</li>
    <li class="list-group-item">Enhance Creativity: Sparks imagination and creativity in construction and transportation play.</li>
    <li class="list-group-item">Educational Fun: Develops problem-solving skills and promotes hands-on learning.</li>
  </ul>`,
    desciption:`<div id="dasdesc">
    <p><strong>Perfect Replacement Cars:</strong> These magnetic cars are designed as ideal replacements for your magnetic tile building set, ensuring uninterrupted playtime.</p>

<p><strong>Seamless Compatibility:</strong> Our replacement cars seamlessly integrate with popular magnetic tile sets, making them a perfect match for your existing collection.</p>

<p><strong>Durable and Safe:</strong> Crafted from high-quality, child-friendly materials, these cars are built to withstand playtime while keeping your child safe.</p>

<p><strong>Enhance Creativity:</strong> Spark your child's imagination and creativity as they explore a world of construction and transportation with these versatile magnetic cars.</p>

<p><strong>Educational Fun:</strong> These cars are not just for play; they also help develop problem-solving skills and promote learning through hands-on exploration.</p>

    </div>`,

},{
    src:'../../images/mag-blocks/images/4-Modes 08.jpg',
    text:'Mag Shapes, Magnetic Tiles Compatible with Legos ',
    label:'mag-shapes-z01',
    path:'/item/mag-shapes-z01',
    barcode: 5060831600782,
    weight: "0.353 KG",
    dimations: '11 X 11 X 11 cm',
    price: 12.99,
    howToUseLink: '/product/mag-blocks',
    stores:[
        [
            {store: 'eBay'},
            {link: "https://www.ebay.co.uk/itm/Magnetic-tiles-work-legos-/266455661122"}
        ],
        [
            {store: 'Amazon'},
            {link: "https://www.amazon.co.uk/dp/B0CL5K3B74/"}
        ]
    ],
    
    shortDesc:`<ul class="list-group">
    <li class="list-group-item">Unlock limitless creativity with our innovative Magnetic Building Tiles. These tiles seamlessly integrate with the beloved LEGO system, offering a unique and versatile play experience for all ages.</li>
    <li class="list-group-item">Endless Building Possibilities: Dual-sided design with LEGO brick compatibility offers unlimited building potential. The only limit is your imagination!</li>
    <li class="list-group-item">Magnetic Attraction: Powerful neodymium magnets ensure stable connections, eliminating wobbly constructions.</li>
    <li class="list-group-item">Vibrant Colors: Available in a spectrum of colors, making your creations eye-catching and dynamic.</li>
    <li class="list-group-item">Educational Fun: Fosters problem-solving, spatial awareness, and fine motor skills, making them a fantastic tool for STEM education.</li>
    <li class="list-group-item">Safe and Durable: Crafted from high-quality, non-toxic materials, built to withstand generations of play.</li>
    <li class="list-group-item">Versatile Play: Suitable for solo play or group activities, create geometric shapes, 3D structures, and more.</li>
    <li class="list-group-item">Suitable for All Ages: Not just for kids, adults can also enjoy the meditative and creative aspects, perfect for family bonding time.</li>
    <li class="list-group-item">Elevate your playtime with Magnetic Building Tiles. Unleash your inner architect, engineer, or artist and bridge the gap between classic building blocks and the magnetic marvels of the future!</li>
</ul>`,
    desciption:`<p>🧲 Unlock limitless creativity with our innovative Magnetic Building Tiles. These tiles seamlessly integrate with the beloved LEGO system, offering a unique and versatile play experience for all ages.</p>

    <p>🏗️ Endless Building Possibilities: Dual-sided design with LEGO brick compatibility offers unlimited building potential. The only limit is your imagination!</p>
    
    <p>🧲 Magnetic Attraction: Powerful neodymium magnets ensure stable connections, eliminating wobbly constructions.</p>
    
    <p>🌈 Vibrant Colors: Available in a spectrum of colors, making your creations eye-catching and dynamic.</p>
    
    <p>🧠 Educational Fun: Fosters problem-solving, spatial awareness, and fine motor skills, making them a fantastic tool for STEM education.</p>
    
    <p>🛡️ Safe and Durable: Crafted from high-quality, non-toxic materials, built to withstand generations of play.</p>
    
    <p>🎮 Versatile Play: Suitable for solo play or group activities, create geometric shapes, 3D structures, and more.</p>
    
    <p>👶👴 Suitable for All Ages: Not just for kids, adults can also enjoy the meditative and creative aspects, perfect for family bonding time.</p>
    
    <p>🌆 Elevate your playtime with Magnetic Building Tiles. Unleash your inner architect, engineer, or artist and bridge the gap between classic building blocks and the magnetic marvels of the future!</p>`

    
}

] 


export const productsList = [
{
            src:'../../images/box-lower-res.jpg',
            text:'Mag Shapes. This box contains 73 pieces, many differant shapes.',
            label:'mag-shapes',
            path:'/product/mag-shapes'
},{
            src:'../../images/116.jpg',
            text:'Tracks, this box comes with 116 pieces, its all around a wondfull toy.',
            label:'mag-tracks',
            path:'/product/mag-tracks'
}
] 