import './productDetails.css'
import {productImages} from '../../data'
import VideoGallery from '../videoGallery/VideoGallery'
import WSPGallery from '../WSPGallery/WSPGallery'
import { Card, Col, Container, Row } from 'react-bootstrap'

// console.log(href.substring(href.lastIndexOf('/') + 1))

const url = window.location.href;
const lastSegmentOfUrl = url.split("/").pop();

 
export default function ProductDetails(props) {

    var imageList = productImages[props.name]['images']
   
    var videoList = productImages[props.name]['videos']
    


    return (
        <>
        <div>
            <h1>{productImages[props.name].name}</h1>
            <div>
                <WSPGallery galleryImages={imageList} folderName={props.name}/>
            </div>  
            <div>
                <ul className='video-gallery'>
                    {videoList.map((item, i) => {    
                        return <VideoGallery name={props.name + "/videos/" + item} key={i}/>
                    })}
                </ul>
            </div>



            
        </div>


        <Container style={{marginBottom:'10px'}} fluid={true}  >
          
   
   
        {lastSegmentOfUrl ===  "mag-shapes" ? (
            <Card>
                <Card.Header>How it works</Card.Header>
                <Card.Body>
                <Row>
                <Col lg='6'> <p>From Tall towers, hourse and car ideas are endless just need to start. butting two tiles next to each other and then a 3rd.</p></Col>
                <Col lg='6'>
            <img src="../../images/mag-shapes/images/1.png" style={{maxWidth:"100%"}} alt="track"/>
            </Col>
            </Row>
                </Card.Body>
            
            </Card>


    ): (
        <Row>
            <Col>

           
          
                <Card>
                            <Card.Header>How it works</Card.Header>
                            <Card.Body>
                        
                            <Row>
                                <Col> <p>Building the tracks comes with unlimited ideas and fun. Just start by buting two magnatic tracks togeather and then another ...</p>
                                    <p>Try to make sure that your tracks form a loop. so the cars and just go on and on.</p>
                                </Col>
                                <Col>
                                    <img src="../../images/mag-tracks/lowres/1.jpg" alt='tracks'/>
                                </Col>
                            </Row>
                            <div style={{background:'rgb(0 0 0 / 20%)',padding:"1em"}} mt="1">
                                <Row >
                                        <Col> <img src="../../images/mag-tracks/peices/ramp.jpg" style={{maxHeight: '300px'}} alt="tracks"/></Col>
                                        <Col> <p>The multi level option is just more fun, use the ramp peices with the bridge and start climing</p></Col>
                                </Row>
                            </div>

                    
                            </Card.Body>
                </Card>
            </Col>
        </Row>
    ) }





</Container>
</>
        
    )
}
