import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../App.css';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <h1 className="title">About Us</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <h2 className="mt-5">About Mag Shapes</h2>
          <p>Welcome to Mag Shapes, a brand that's part of the Onlineforu LTD family. Our journey began with a passion for creativity and education, aiming to inspire young minds through innovative play. We specialize in magnetic tiles and building sets, designed to ignite imagination, enhance learning, and foster endless possibilities.</p>
        </Col>
        <Col lg={3}>
          <h2 className="mt-5">Our Mission</h2>
          <p>At Mag Shapes, our mission is to provide high-quality, safe, and educational toys that empower children to explore, create, and learn. We're dedicated to offering products that promote STEAM (Science, Technology, Engineering, Arts, and Mathematics) education while providing hours of entertainment and discovery.</p>
        </Col>
        <Col lg={3}>
          <h2 className="mt-5">Our Commitment</h2>
          <p>We're committed to delivering exceptional products that meet the highest safety standards, ensuring that every playtime moment is worry-free for parents and full of excitement for children. Our commitment to quality extends from the materials we use to the design of our products, providing durable and safe options for your family.</p>
        </Col>
        <Col lg={3}>
          <h2 className="mt-5">Contact Us</h2>
          <p>If you have any questions or inquiries, we'd love to hear from you. Feel free to <Link to='/sign-up'>Contact Us</Link>, and our dedicated team will be happy to assist you.</p>
        </Col>
      </Row>
    </Container>
  );
}
