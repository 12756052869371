import React from 'react'
import { Link, useParams } from "react-router-dom";
import { itemList } from '../../data';
import './itemInfo.css';
import { Col, Container, Row, Image , Accordion, ListGroup, ListGroupItem, Card, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ItemInfo() {
    const params = useParams();
    var name = params.name
    var item =  itemList.find(x => x.label === name);
    

    


  return (
    <>
    <Container fluid={true} className="p-0 itemContainer">

        <Row className='mb-2'>
            <div className="titleDiv">
                <Container>
                    <h2 className='title'>{item.text}</h2>
                </Container>
            </div>
        </Row>
        <Row>
        <div className='mb-5'>
            <Container>
                <Row>
                    <Col md={6}>
                        <Image src={item.src} card-img-top mb-5 mb-md-0  style={{maxWidth:'100%', maxHeight:'100%'}}  alt="" ></Image>
                    </Col>
                    <Col md={6} className="align-self-center">
                        <div className='mb-3'>
                            <Card> 
                                <div dangerouslySetInnerHTML={{__html: item.shortDesc}} />
                            </Card> 
                        </div>
                        <Card>
                            <Card.Body>
                                <Card.Title>Where To Buy</Card.Title>  

                                <Card.Text>  
                                Price Just £{item.price}
                                </Card.Text>
                            
                                {item.stores.map(function(object, i){ 
                                    return  (
                                    <a  target="_blank" rel="noopener noreferrer" href={`${object[1].link}`} >
                                        <Button className="storesButton">{object[0].store}</Button>
                                    </a>
                                    )
                                    })}
                            </Card.Body>
                        </Card>
                        <div className='mt-3'>               
                        <Card>
                            <Card.Body>
                                <div className="d-grid gap-2">
                                    <Link to={item.howToUseLink}>
                                        <Button className="storesButton" size="lg" style={{width: "100%"}}  variant="secondary">See More Product Info</Button>
                                        </Link>
                                </div>
                                <Card.Text> </Card.Text>
                            </Card.Body>
                        </Card>
                        </div>

                       
                    </Col>
                </Row>
            </Container>
            </div>
          </Row>


          <Container >

        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Desciption</Accordion.Header>
                <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: item.desciption}} /> 
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Extra Info</Accordion.Header>
                <Accordion.Body>
                    <ListGroup>
                        
                        <ListGroupItem>Barcode: {item.barcode}</ListGroupItem>
                        <ListGroupItem>Weight: {item.weight}</ListGroupItem>
                        <ListGroupItem>Dimations: {item.dimations}</ListGroupItem>
                    </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

<br/>



       

    </Container>
    
    </Container>
   
    </>
  )
}