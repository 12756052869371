import React from 'react';
import './footer.css'

import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
// import { Button } from '../button/Button'

function Footer() {
  return (
    <div className='footer-container'>
     
      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/sign-up'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Our Sites</h2>
            
           <a href='ykbeauty.uk' target="_blank" rel="noreferrer">YK Beauty</a>
           <a href='onlineforu.uk' target="_blank" rel="noreferrer">Onlineforu LTD</a>
           <a href='salesshop.cok' target="_blank" rel="noreferrer">Sales Shop</a>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a href='https://www.instagram.com/mag_shapes/' target="_blank" rel="noreferrer">Instagram</a>
            <a href='https://www.facebook.com/MagShapes' target="_blank" rel="noreferrer">FaceBook</a>
          </div>

        </div>

      </div> */}


      <Container>
        <Row>
        
        
          <Col md={3}>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
          </div>
          </Col>
          <Col md={3}>
             <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/sign-up'>Contact</Link>
          </div></Col>
          <Col md={3}>
            <div class='footer-link-items'>
            <h2>Our Sites</h2>
           <a href='https://ykbeauty.uk' target="_blank" rel="noreferrer">YK Beauty</a>
           <a href='https://onlineforu.co.uk/' target="_blank" rel="noreferrer">Onlineforu LTD</a>
           <a href='https://salesshop.co' target="_blank" rel="noreferrer">Sales Shop</a>
          </div></Col>
          <Col md={3}>
            <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a href='https://www.instagram.com/mag_shapes/' target="_blank" rel="noreferrer">Instagram</a>
            <a href='https://www.facebook.com/MagShapes' target="_blank" rel="noreferrer">FaceBook</a>
          </div></Col>       
        </Row>
      </Container>

      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            <figure className=''>
            <img
              className='logo-img'
              alt='Logo'
              src="../../images/logo-icon.png"
            />
          </figure>
            </Link>
          </div>
          <small class='website-rights'>Mag-shapes © 2023</small>
          <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href='https://www.facebook.com/MagShapes'
              target='_blank'
              aria-label='Facebook'
              rel="noreferrer"
            >
              <i class='fab fa-facebook-f' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/mag_shapes/'
              target='_blank'
              aria-label='Instagram'
              rel="noreferrer"
            >
              <i class='fab fa-instagram' />
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer;