import React from 'react';
import Navbar from '../src/components/navbar/Navbar'
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from './components/pages/About';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Footer from './components/footer/Footer';
import ProductInfo from './components/pages/ProductInfo';
import ItemInfo from './components/itemInfo/ItemInfo';

function App() {
  
  return (
    <>
    {/* basename="/mag-shapes" */}
      <Router  > 
        <Navbar />
        <Routes>
          <Route path="/" element={<Home/>} />          
          <Route path='/about' element={<About/>} />
          <Route path='/products' element={<Products/>} />
          <Route path='/product/mag-tracks' element={<ProductInfo name="mag-tracks"/>} />
          <Route path='/product/mag-shapes' element={<ProductInfo name="mag-shapes"/>} />
          <Route path='/product/mag-blocks' element={<ProductInfo name="mag-blocks"/>} />
          <Route path='/item/:name' element={<ItemInfo/>} />
          
          <Route path='/sign-up' element={<SignUp/>} />
        </Routes>
        <Footer/>
      </Router>
     
    </>
  );
}

export default App;
