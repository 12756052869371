import React from 'react';
import '../../App.css';
import Cards from '../cards/Cards'
import HeroSection from '../heroSection/HeroSection'
import {productsList} from '../../data'

function Home() {
  window.scrollTo(0, 0)
  return (
    <>   
      <HeroSection />
      <Cards title = "The Fun Starts Here" productsList = {productsList}/>;
     
    </>
  );
}

export default Home;