import './videoGallery.css'


export default function VideoGallery(props) {
  return (
    <>
   
      <li className='video__item'>
        
      <video controls  >
      <source src={"../../images/"+props.name} type="video/mp4"/>
        </video>

        
      </li>
    </>
  )
}

