import React from 'react';
import '../../App';
// import { Button } from '../button/Button'
import { Button } from 'react-bootstrap';
import './heroSection.css';
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Mag Shapes</h1>
      <p>What are you waiting for?</p>
    
      <div className="d-grid gap-2 hero-btns">
      
  
        <Button
          variant="outline-light"
          size="lg"      
        >
           <Link to='/products' className='heroLink'> See Our Range</Link>
        </Button>

      
      </div>
    </div>
  );
}

export default HeroSection;