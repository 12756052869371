import React from 'react';
import './cards.css';
import CardItem from '../cardItem/CardItem'
import { Col, Container, Row,  ListGroup, Card, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Cards(props) {
  return (
    <>
    <Container fluid={true} className="p-0 itemContainer">

        <Row className='mb-2'>
            <div className="titleDiv">
                <Container>
                    <h2 className='title'>{props.title}</h2>
                </Container>
            </div>
        </Row>
      </Container>
    <div className='cards'>
      {/* <h1>{props.title}</h1> */}
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {props.productsList.map((item, i) => { 
             
              return <CardItem
                src={item.src}
                text={item.text}
                label={item.label}
                path={item.path}
             />
            })}
            
         
          </ul>
         
        </div>
      </div>
    </div>
    </>
  );
}

export default Cards;